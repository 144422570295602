import React from "react";
import { Switch, Route } from "react-router-dom";
import Home from "../page/Home";
import About from "../page/About";
import Search from "../page/Search";
import Privacy from "../page/Privacy";
import Terms from "../page/Terms";
import NotFound from "../page/NotFound";

import Theme from "../page/Theme";
import FormTest from "../page/FormTest";
import Playground from "../page/Playground";

export default function Main(props) {
  const { q, setQ, theme, setTheme, darkNavbar, setDarkNavbar } = props;
  return (
    <main role="main" className="flex-shrink-0 bg-light">
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/search">
          <Search q={q} setQ={setQ} />
        </Route>
        <Route path="/privacy">
          <Privacy />
        </Route>
        <Route path="/terms">
          <Terms />
        </Route>

        <Route path="/theme">
          <Theme
            theme={theme}
            setTheme={setTheme}
            darkNavbar={darkNavbar}
            setDarkNavbar={setDarkNavbar}
          />
        </Route>

        <Route path="/form-test">
          <FormTest />
        </Route>

        <Route path="/playground">
          <Playground />
        </Route>

        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </main>
  );
}
