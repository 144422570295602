import React from 'react';

export default function CssLink(props) {
  const { theme } = props;
  if (!theme || theme.length <= 0 || theme === 'bootstrap') {
    return null;
  }
  const href = `/css/bootstrap.${theme}.min.css`;
  return <link rel="stylesheet" type="text/css" href={href} />;
}
