import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageContainer, fromMd } from '../Util';
import Constant from '../Constant';

export default function Privacy(props) {
  const {
    APP_NAME: name,
    LINK: link,
    EMAIL: email,
    COUNTRY: country,
  } = Constant;

  const { t } = useTranslation();
  const md = t(`privacy`, { name, link, email, country });

  return (
    <PageContainer
      title={t('Privacy Policy')}
      className="p-5 bg-white text-dark rounded shadow-lg"
    >
      <div dangerouslySetInnerHTML={fromMd(md)} />
    </PageContainer>
  );
}
