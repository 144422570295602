import React from 'react';
import PLAYGROUND_SAMPLE_CODE_LIST from './PLAYGROUND_SAMPLE_CODE_LIST';

const SAMPLE_CODE_LIST_BUTTON_STYLE = {
  height: 75,
  overflow: 'hidden',
  fontSize: '1px',
};

export default function PlaygroundSampleCodeList(props) {
  const { className = '', onChange } = props;
  const classNames = [className, 'row form-row'];

  function handleClick(e, code) {
    e.preventDefault();
    if (onChange) onChange(code);
  }

  return (
    <div className={classNames.join(' ')}>
      {PLAYGROUND_SAMPLE_CODE_LIST.map((code, i) => {
        return (
          <div key={i} className="col">
            <button
              className="btn-unstyle btn-block rounded-sm text-left p-2 bg-white text-dark shadow-sm"
              style={SAMPLE_CODE_LIST_BUTTON_STYLE}
              onClick={e => handleClick(e, code)}
            >
              {code}
            </button>
          </div>
        );
      })}
    </div>
  );
}
