import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Row, Col, PageContainer, randInt } from '../Util';

const PLAY_IMAGES = [
  '/img/undraw_children_4rtb-red.svg',
  '/img/undraw_gaming_6oy3-red.svg',
  '/img/undraw_junior_soccer_6sop-red.svg',
  '/img/undraw_toy_car_7umw-red.svg',
];

function randPlayImage() {
  return PLAY_IMAGES[randInt(PLAY_IMAGES.length)];
}

export default function Home(props) {
  const { t } = useTranslation();

  const [imgSrc] = React.useState(randPlayImage());

  return (
    <PageContainer title={t('Home')}>
      <Row formRow>
        <Col n={12} sm={4}>
          <Card
            imgSrc={imgSrc}
            linkTo="/playground"
            linkClassName="btn btn-success btn-block shadow-sm"
            linkLabel={
              <div>
                <span className="float-left">{'\u25b6'}</span>
                <span>{t('Live React Editor')}</span>
              </div>
            }
          />
        </Col>
      </Row>
    </PageContainer>
  );
}
