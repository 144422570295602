const PLAYGROUND_SAMPLE_CODE_LIST = [
  `function App(props) {
  return <h1>Hello World</h1>
}
render(<App />)
`,

  `function App(props) {

    const [active,setActive] = React.useState(false)

    React.useEffect(()=>{
      if (!active) setActive(true)
    }, [active])

  if (!active) return null

  return (
    <div className="p-3 bg-white text-dark shadow-lg text-center">
      <h1 className="text-dark animated faster lightSpeedIn delay-500ms">Hello World</h1>
      <p className="text-dark h5 animated faster fadeInDown delay-1s">How're you doing?</p>
      <p className="text-dark h5 animated faster fadeInDown delay-1500ms">Hope you're doing well</p>
      <p className="text-dark h1 animated faster fadeIn delay-2s">{'\u{1f60a}'}</p>
      <div className="animated faster fadeIn delay-2500ms">
        <p className="text-dark h5 animated rubberBand delay-2750ms">Thank you</p>
      </div>
      <div className="animated faster fadeIn delay-3s">
        <p className="text-dark h5 animated swing delay-3500ms">Have a nice day!</p>
      </div>
      <div style={{position:'absolute',bottom:'.5rem',right:'.5rem',zIndex:99}} data-tootik="replay">
        <button className="btn btn-primary rounded-circle shadow animated rotateInDownRight faster delay-4500ms" style={{width:50,height:50,overflow:'hidden'}} onClick={e=>setActive(false)}>&#x21ba;</button>
      </div>
    </div>
  )
}
render(<App />)
`,

  `function Counter(props) {
  const {className,onStart} = props
  const [n,setN] = React.useState(0)
  const [message,setMessage] = React.useState('')
  const [startTime,setStartTime] = React.useState()

  function handleClick(e) {
    setN(n+1)
    if (onStart) onStart()
  }

  React.useEffect(()=>{
    if (n===1) {
      setStartTime(Date.now())
    } else if (n!=0 && n%10===0) {
      const timeDiff = (Date.now() - startTime)/1000
      setMessage()
      setTimeout(()=>{
        setMessage("You've reached " + n + " in " + timeDiff + " seconds!")
      }, 0)
    }
  }, [n])

  return (
    <div className={className}>
      <button className="btn btn-success btn-lg shadow-sm" style={{minWidth:60}} onClick={handleClick}>{n}</button>

      {message&&<p className="mt-4 animated faster tada">{message}</p>}
    </div>
  )
}

function App(props) {
  const [started,setStarted] = React.useState(false)
  return (
    <div className="p-3 pt-5 pb-5 w-100 rounded bg-white text-dark shadow-lg text-center">
      <h3 className="mb-4 text-dark animated faster fadeInUp delay-1s">Counter</h3>

      <Counter className="mb-4 animated faster flipInX delay-1500ms" onStart={e=>setStarted(true)} />

      {!started&&<p className="animated faster bounceIn delay-2s">Start counting <span className="animated ">NOW</span></p>}
    </div>
  )
}

render (<App />)`,
];

export default PLAYGROUND_SAMPLE_CODE_LIST;
