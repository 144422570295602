import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Constant from '../Constant';

export default function Footer(props) {
  const { t } = useTranslation();
  return (
    <footer className="footer mt-auto py-3">
      <div className="container text-center">
        <div className="row mb-3">
          <div className="col-6 text-right">
            <Link to="/privacy" className="text-muted">
              {t('Privacy Policy')}
            </Link>
          </div>
          <div className="col-6 text-left">
            <Link to="/terms" className="text-muted">
              {t('Terms & Conditions')}
            </Link>
          </div>
        </div>
        <span className="text-muted">
          &copy; {new Date().getFullYear()} {Constant.APP_LOGO}
          {Constant.APP_CREATOR}
        </span>
      </div>
    </footer>
  );
}
