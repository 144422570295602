import React from 'react';
import Body from './component/Body';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';
import 'tootik/css/tootik.min.css';
import './style/style.css';
import './style/media.css';

export default function App() {
  return <Body />;
}
