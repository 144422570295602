import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageContainer, Jumbotron, isUrl, isEmail } from '../Util';
import Constant from '../Constant';

const ABOUT_DATA = {
  link: Constant.LINK,
  email: Constant.EMAIL,
  'from country': Constant.COUNTRY,
  since: Constant.SINCE,
};

export default function About(props) {
  const { t } = useTranslation();
  return (
    <PageContainer title={t('About')}>
      <Jumbotron
        title={
          <div className="text-center">
            <small className="mr-2 text-primary font-xx-large d-block animated faster fadeInUp delay-500ms">
              {t('Created by')}
            </small>
            <div className="animated faster fadeInDown delay-750ms">
              {Constant.APP_LOGO} {Constant.APP_CREATOR}
            </div>
          </div>
        }
        lead={
          <div className="text-center animated faster fadeInUp delay-1s">
            {t(Constant.TAGLINE)}
          </div>
        }
        className="bg-light shadow mb-4"
      />

      <div className="p-3 text-center">
        {Object.keys(ABOUT_DATA).map((k, i) => {
          const v = ABOUT_DATA[k];
          const render = () => {
            if (isEmail(v)) {
              const href = `mailto:${v}`;
              return (
                <a
                  key={k}
                  href={href}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {v}
                </a>
              );
            }

            if (isUrl(v)) {
              return (
                <a key={k} href={v} target="_blank" rel="noopener noreferrer">
                  {v}
                </a>
              );
            }

            return v;
          };
          return (
            <div
              key={k}
              className="mb-5 animated faster fadeInUp"
              style={{ animationDelay: `${i * 0.25 + 1.25}s` }}
            >
              <label className="text-uppercase">{t(k)}</label>
              <h5>{render()}</h5>
            </div>
          );
        })}
      </div>
    </PageContainer>
  );
}
