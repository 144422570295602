import React from 'react';
import Navbar from './Navbar';

export default function Header(props) {
  const { q, setQ, language, setLanguage, darkNavbar } = props;
  return (
    <header>
      <Navbar
        q={q}
        setQ={setQ}
        language={language}
        setLanguage={setLanguage}
        darkNavbar={darkNavbar}
      />
    </header>
  );
}
