import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from './Header';
import Main from './Main';
import Footer from './Footer';
import CssLink from './CssLink';
import Storage from './Storage';
import { ScrollToTop } from '../Util';

const KEY_SETTINGS = 'settings';
const SETTINGS = Storage.get(KEY_SETTINGS, {});

// default values
const LANGUAGE = SETTINGS.language || 'en';
const THEME = SETTINGS.theme || 'materia';
const DARK_NAVBAR = !!SETTINGS.darkNavbar;

export default function Body(props) {
  const { i18n } = useTranslation();

  const [q, setQ] = React.useState('');

  const [language, setLanguage] = React.useState(LANGUAGE);
  const [theme, setTheme] = React.useState(THEME);
  const [darkNavbar, setDarkNavbar] = React.useState(DARK_NAVBAR);
  const [scrollY, setScrollY] = React.useState(window.scrollY);

  React.useEffect(() => {
    function handleScroll() {
      const scrollY = window.scrollY;
      setScrollY(scrollY);
    }
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  React.useEffect(() => {
    i18n.changeLanguage(language);
  }, [i18n, language]);

  React.useEffect(() => {
    Storage.set(KEY_SETTINGS, { language, theme, darkNavbar });
  }, [language, theme, darkNavbar]);

  return (
    <>
      <CssLink theme={theme} />
      <Header
        q={q}
        setQ={setQ}
        language={language}
        setLanguage={setLanguage}
        darkNavbar={darkNavbar}
      />
      <Main
        q={q}
        setQ={setQ}
        theme={theme}
        setTheme={setTheme}
        darkNavbar={darkNavbar}
        setDarkNavbar={setDarkNavbar}
      />
      <ScrollToTop visible={scrollY > 50} />
      <Footer />
    </>
  );
}
