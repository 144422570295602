import React from 'react';
import { Container } from '../Util';
import SearchBox from '../component/SearchBox';

export default function Search(props) {
  const { q, setQ } = props;

  return (
    <Container>
      <h1>Search</h1>

      <SearchBox q={q} setQ={setQ} className="w-100" />

      <hr />

      <h3>
        Search results for <span className="text-muted">{q}</span>
      </h3>
    </Container>
  );
}
