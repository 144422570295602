import React from 'react';
import marked from 'marked';
import { Link } from 'react-router-dom';

export function randInt(n = 10) {
  return Math.floor(Math.random() * n);
}

export function randColor() {
  return `#${[...Array(3).keys()]
    .map(e => (randInt(238) + 17).toString(16))
    .join('')}`;
}

export function generateId(prefix = '_', l = 6) {
  return `${prefix}${Date.now()}${new Array(l)
    .fill(0)
    .map(e => randInt())
    .join('')}`;
}

export function fromMd(v) {
  if (typeof v === 'string') {
    marked.setOptions({
      gfm: true,
    });
    return { __html: marked(v) };
  }
  return { __html: '' };
}

export function isUrl(s) {
  if (typeof s === 'string' && s.length > 0) {
    return s.startsWith('http') && s.indexOf('://') > 0;
  }
  return false;
}

// eslint-disable-next-line
const REGEX_EMAIL = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export function isEmail(s) {
  return REGEX_EMAIL.test(s);
}

export function toNumber(n, d = -1) {
  if (typeof n === 'number' && !isNaN(n)) return n;
  if (typeof n === 'string' && n.length > 0) {
    if (n.indexOf('.') >= 0) return toNumber(parseFloat(n), d);
    else return toNumber(parseInt(n), d);
  }
  if (Array.isArray(n) && n.length > 0) return toNumber(n[0], d);
  if (typeof n === 'object' && n !== null) {
    const keys = Object.keys(n);
    if (Array.isArray(keys) && keys.length > 0) return toNumber(n[keys[0]], d);
  }
  if (typeof d === 'number' && !isNaN(d)) return d;
  return -1;
}

export function lipsum(n = 10, dot = false) {
  if (typeof n !== 'number' || (typeof n === 'number' && n < 1)) n = 10;
  const words = [
    'lorem',
    'ipsum',
    'dolor',
    'sit',
    'amet',
    'consectetur',
    'adipiscing',
    'elit',
    'vivamus',
    'et',
    'accumsan',
    'augue',
    'duis',
    'eget',
    'nunc',
    'id',
    'sodales',
    'finibus',
    'vestibulum',
    'sagittis',
    'magna',
    'nec',
    'rutrum',
    'volutpat',
    'risus',
    'tincidunt',
    'justo',
    'non',
    'gravida',
    'tortor',
    'enim',
    'in',
    'urna',
    'ut',
    'vel',
    'metus',
    'pellentesque',
    'porttitor',
    'vitae',
    'nisi',
    'nullam',
    'faucibus',
    'condimentum',
    'quam',
    'imperdiet',
    'class',
    'aptent',
    'taciti',
    'sociosqu',
    'ad',
    'litora',
    'torquent',
    'per',
    'conubia',
    'nostra',
    'inceptos',
    'himenaeos',
    'interdum',
    'malesuada',
    'fames',
    'ac',
    'ante',
    'primis',
    'curabitur',
    'nibh',
    'quis',
    'iaculis',
    'cras',
    'mollis',
    'eu',
    'congue',
    'leo',
  ];
  const count = n;
  const sentence = [];
  const indexes = new Array(count)
    .fill(0)
    .map(index => Math.floor(Math.random() * words.length));
  indexes.forEach((index, i) => {
    const word = words[index];
    if (i === 0) sentence.push(word.charAt(0).toUpperCase() + word.substr(1));
    else sentence.push(word);
  });
  if (dot) return sentence.join(' ').concat('.');
  return sentence.join(' ');
}

export function getDimension() {
  if (typeof window === 'object') {
    const { innerWidth, innerHeight } = window;
    return { width: innerWidth, height: innerHeight };
  }
  return {};
}

export function useDimension() {
  const [dimension, setDimension] = React.useState(getDimension());
  React.useEffect(() => {
    function handleResize() {
      setDimension(getDimension());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return dimension;
}

export function FlexContainer(props) {
  const { children, deg = randInt(360), bg, style = {}, flex = true } = props;
  const classNames = [
    'align-content-center align-items-center justify-content-center flex-wrap w-100 v-100 vh-100',
  ];
  if (Array.isArray(bg) && bg.length > 0) {
    style.background = `linear-gradient(${deg}deg,${bg.join(',')})`;
  }
  if (flex) classNames.push('d-flex');
  return (
    <div className={classNames.join(' ')} style={style}>
      {children}
    </div>
  );
}

export function TableEditor(props) {
  const { value, onChange, className = '' } = props;
  const classNames = ['table-sm', className];
  function handleChange(e, i, j) {
    let v = e.target.value;
    if (
      typeof v === 'string' &&
      v.length > 0 &&
      v.charAt(v.length - 1) !== '.'
    ) {
      const n = Number(v);
      if (typeof n === 'number' && !isNaN(n)) {
        v = n;
      }
    }
    onChange(
      [
        ...value.slice(0, i),
        [...value[i].slice(0, j), v, ...value[i].slice(j + 1)],
        ...value.slice(i + 1),
      ],
      i,
      j,
    );
  }
  function render(e, i, j) {
    return (
      <input
        value={e}
        className="form-control"
        onChange={e => handleChange(e, i, j)}
      />
    );
  }
  return (
    <Table value={value} render={render} className={classNames.join(' ')} />
  );
}

export function Table(props) {
  const { value, className = '', render } = props;
  if (Array.isArray(value) && value.length > 0) {
    const classNames = ['table', className];
    return (
      <table className={classNames.join(' ')}>
        <tbody>
          {value.map((e, i) => {
            if (typeof e === 'object' && e !== null) {
              return (
                <tr key={i}>
                  {Object.keys(e).map((k, j) => {
                    const v = e[k];
                    function renderValue() {
                      if (typeof render === 'function') return render(v, i, j);
                      return v;
                    }
                    return <td key={j}>{renderValue()}</td>;
                  })}
                </tr>
              );
            }
            return null;
          })}
        </tbody>
      </table>
    );
  }
  return null;
}

export function Details(props) {
  const { summary = 'details', children, className = '' } = props;
  return (
    <details className={className}>
      <summary>{summary}</summary>
      <div className="mt-3 animated faster slideInDown">{children}</div>
    </details>
  );
}

export function PreCode(props) {
  const { ugly, className = '', children } = props;

  if (typeof children === 'object' && children !== null) {
    if (ugly) {
      return <PreCode {...props}>{JSON.stringify(children)}</PreCode>;
    }
    return <PreCode {...props}>{JSON.stringify(children, null, 2)}</PreCode>;
  }

  return (
    <pre className={`p-3 bg-dark text-white rounded shadow ${className}`}>
      <code>{children}</code>
    </pre>
  );
}

// usage <Box random />
// <Box backgroundColor={randColor()} />
export function Box(props) {
  const {
    className = '',
    children,
    style = { minHeight: 50 },
    backgroundColor,
    random = false,
  } = props;

  const classNames = [
    className,
    'w-100 d-flex justify-content-center align-items-center',
  ];

  if (typeof backgroundColor === 'string' && backgroundColor.length > 0)
    style.backgroundColor = backgroundColor;

  if (random) style.backgroundColor = randColor();

  return (
    <div className={classNames.join(' ')} style={style}>
      {children}
    </div>
  );
}

export function Section(props) {
  const { children, className = '', style = {}, center = false } = props;
  const classNames = ['p-3 bg-white rounded shadow', className];
  if (center) classNames.push('text-center');

  const mb = toNumber(props.mb, 3);
  if (typeof mb === 'number' && mb >= 0 && mb <= 5) classNames.push(`mb-${mb}`);

  return (
    <div className={classNames.join(' ')} style={style}>
      {children}
    </div>
  );
}

export function Checkmark(props) {
  return (
    <span
      className="mr-2 badge-success rounded-circle shine text-white"
      style={{
        display: 'inline-table',
        width: '1.25rem',
        height: '1.25rem',
        lineHeight: '1.25rem',
        overflow: 'hidden',
        textAlign: 'center',
      }}
    >
      {'\u2713'}
    </span>
  );
}

function scrollToTop() {
  const scrollTop =
    document.documentElement.scrollTop || document.body.scrollTop;
  if (scrollTop > 0) {
    window.requestAnimationFrame(scrollToTop);
    window.scrollTo(0, scrollTop - scrollTop / 8);
  }
}

const SCROLL_TO_TOP_GAP = '5rem';

// \u2191 \u22c0 \u2303 - arrow up chars
export function ScrollToTop(props) {
  const { visible = true, top = false, left = false, style = {} } = props;

  if (!visible) {
    return null;
  }

  if (top) style.top = SCROLL_TO_TOP_GAP;
  else style.bottom = SCROLL_TO_TOP_GAP;

  if (left) style.left = SCROLL_TO_TOP_GAP;
  else style.right = SCROLL_TO_TOP_GAP;

  return (
    <div
      className="ScrollToTop position-fixed animated faster fadeInUp"
      style={{ ...style, zIndex: 99 }}
      data-tootik="Scroll to top"
      data-tootik-conf={`${left ? 'right' : 'left'} shadow`}
    >
      <button
        className="btn text-light text-center rounded-circle shadow"
        style={{ opacity: 0.75, width: 60, height: 60, overflow: 'hidden' }}
        onClick={e => scrollToTop()}
      >
        {'\u2191'}
      </button>
    </div>
  );
}

/* ract-bootstrap-form */

export function InputSwitch(props) {
  const {
    className = '',
    label = '',
    disabled = false,
    value = false,
    onChange,
  } = props;
  const [id] = React.useState(generateId());

  function handleChange(e) {
    if (onChange) onChange(e.target.checked);
  }

  return (
    <div className={`custom-control custom-switch ${className}`}>
      <input
        type="checkbox"
        className="custom-control-input"
        id={id}
        disabled={disabled}
        value={value}
        checked={value}
        onChange={handleChange}
      />
      <label className="custom-control-label" htmlFor={id}>
        {label}
      </label>
    </div>
  );
}

export function InputMarkdown(props) {
  const { value = '', onChange } = props;

  function handleChange(e) {
    e.preventDefault();
    if (onChange) onChange(e.target.value);
  }

  return (
    <div className="form-row">
      <div className="col-6">
        <textarea
          value={value}
          className="form-control shadow-sm"
          onChange={handleChange}
          rows={5}
        />
      </div>
      <div className="col-6">
        <div
          style={{ top: '-.85rem' }}
          className="w-100 position-absolute text-center"
        >
          <div className="badge badge-dark badge-pill">preview</div>
        </div>
        <div
          dangerouslySetInnerHTML={{ __html: marked(value) }}
          className="p-2 rounded"
          style={{ border: '1px dashed #dee2e6', wordBreak: 'break-all' }}
        />
      </div>
    </div>
  );
}

export function InputNumber(props) {
  const { value, onChange } = props;

  const [cache, setCache] = React.useState(value);

  function handleChange(e) {
    setCache(e.target.value);

    try {
      const n = Number(e.target.value);
      if (typeof n === 'number' && !isNaN(n)) {
        //if (n===0&&e.target.value==='') onChange(undefined)
        if (onChange) onChange(n);
      } else {
        if (onChange) onChange(value);
      }
    } catch (e) {}
  }

  return (
    <input
      type="number"
      value={cache}
      onChange={handleChange}
      className="form-control shadow-sm"
    />
  );
}

export function Input(props) {
  const { type = 'text', value, onChange } = props;

  if (type === 'text') {
    return (
      <input
        type={type}
        value={value}
        onChange={e => onChange(e.target.value)}
        className="form-control shadow-sm"
      />
    );
  }

  if (type === 'number') {
    return <InputNumber value={value} onChange={onChange} />;
  }

  if (type === 'md' || type === 'markdown') {
    return <InputMarkdown value={value} onChange={onChange} />;
  }

  return null;
}

export function FormInput(props) {
  const { label = '', type, value, onChange } = props;
  return (
    <div className="form-group">
      <label>{label}</label>
      <Input type={type} value={value} onChange={onChange} />
    </div>
  );
}

export function Form(props) {
  const { template = [], onSubmit, preview = false } = props;

  const [value, setValue] = React.useState(props.value || {});

  function handleChange(label, v) {
    setValue({ ...value, [label]: v });
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (onSubmit) onSubmit(value);
  }

  return (
    <div className="form-row">
      <div className="col-8">
        <form onSubmit={handleSubmit} className="mb-3">
          {template.map((e, i) => {
            const { label } = e;
            return (
              <FormInput
                key={i}
                {...e}
                value={value[label]}
                onChange={v => handleChange(label, v)}
              />
            );
          })}

          <div className="text-center">
            <button
              type="submit"
              className="btn btn-primary shadow-sm rounded-pill pl-5 pr-5"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
      {preview && (
        <div className="col-4 border-left">
          <h6 className="text-center">preview</h6>
          <PreCode>{value}</PreCode>
        </div>
      )}
    </div>
  );
}

/* end react bootstrap form */

export function PageContainer(props) {
  const { title = '', className = '', children, ...rest } = props;
  return (
    <Container className="animated faster slideInRight" {...rest}>
      <h1 className="animated faster fadeInDown delay-500ms">{title}</h1>
      <hr className="animated faster fadeInLeft delay-750ms" />
      <div className={className}>{children}</div>
    </Container>
  );
}

/* react-bootstrap */

export function Jumbotron(props) {
  const { fluid = false, className = '', title = '', lead = '' } = props;
  const classNames = [className, 'jumbotron'];
  if (fluid) classNames.push('jumbotron-fluid');
  return (
    <div className={classNames.join(' ')}>
      <div className="container">
        <h1 className="display-4">{title}</h1>
        <div className="lead">{lead}</div>
      </div>
    </div>
  );
}

export function Container(props) {
  const {
    className = '',
    style = {},
    children,
    sm = false,
    md = false,
    lg = false,
    xl = false,
    fluid = false,
  } = props;

  const classNames = [className];

  if (fluid) classNames.push('container-fluid');
  else if (xl) classNames.push('container-xl');
  else if (lg) classNames.push('container-lg');
  else if (md) classNames.push('container-md');
  else if (sm) classNames.push('container-sm');
  else classNames.push('container');

  return (
    <div className={classNames.join(' ')} style={style}>
      {children}
    </div>
  );
}

// usage <Col>...</Col>
// <Col n={12} sm={8} md={6} lg={4} xl={2} />
export function Col(props) {
  const { className = '', children, n, sm, md, lg, xl } = props;
  const classNames = [className];

  if (typeof n === 'number' && n > 0 && n <= 12) classNames.push(`col-${n}`);

  // Extra small <576px	Small ≥576px	Medium ≥768px	Large ≥992px	Extra large ≥1200px
  // Max container width	None (auto)	540px	720px	960px	1140px
  // Class prefix	.col-	.col-sm-	.col-md-	.col-lg-	.col-xl-

  if (typeof sm === 'number' && sm > 0 && sm <= 12)
    classNames.push(`col-sm-${sm}`);
  if (typeof md === 'number' && md > 0 && md <= 12)
    classNames.push(`col-md-${md}`);
  if (typeof lg === 'number' && lg > 0 && lg <= 12)
    classNames.push(`col-lg-${lg}`);
  if (typeof xl === 'number' && xl > 0 && xl <= 12)
    classNames.push(`col-xl-${xl}`);

  if (classNames.length < 2) classNames.push('col');

  return <div className={classNames.join(' ')}>{children}</div>;
}

// usage <Row noGutters>...</Row>
// <Row noGutters><Col></Col><Col></Col></Row>
export function Row(props) {
  const { className = '', noGutters = false, form = false, children } = props;
  const classNames = [className, 'row'];
  if (noGutters) classNames.push('no-gutters');
  if (form) classNames.push('form-row');
  return <div className={classNames.join(' ')}>{children}</div>;
}

export function Badge(props) {
  const {
    className = '',
    style = {},
    children,
    primary = false,
    secondary = false,
    info = false,
    danger = false,
    warning = false,
    light = false,
    dark = true,
    shadow = 'sm',
    border = false,
    pill = false,
  } = props;

  const classNames = [className, 'badge'];

  if (primary) classNames.push('badge-primary');
  else if (secondary) classNames.push('badge-secondary');
  else if (info) classNames.push('badge-info');
  else if (danger) classNames.push('badge-danger');
  else if (warning) classNames.push('badge-warning');
  else if (light) classNames.push('badge-light');
  else if (dark) classNames.push('badge-dark');

  if (pill) classNames.push('badge-pill');

  if (shadow) {
    if (shadow === 'sm') classNames.push('shadow-sm');
    else if (shadow === 'lg') classNames.push('shadow-lg');
    else classNames.push('shadow');
  }

  if (border) classNames.push('border');

  return (
    <div className={classNames.join(' ')} style={style}>
      {children}
    </div>
  );
}

export function Card(props) {
  const {
    className = '',
    style = {},
    imgSrc,
    imgAlt = '',
    title = '',
    description = '',
    linkTo = '',
    linkClassName = 'btn btn-primary shadow-sm btn-block',
    linkLabel = '',
    shadow = 'sm',
    children,
  } = props;

  const classNames = [className, 'card'];

  if (shadow) {
    if (shadow === 'sm') classNames.push('shadow-sm');
    else if (shadow === 'lg') classNames.push('shadow-lg');
    else classNames.push('shadow');
  }

  return (
    <div className={classNames.join(' ')} style={style}>
      {imgSrc && <img src={imgSrc} className="card-img-top" alt={imgAlt} />}
      <div className="card-body">
        <h5 className="card-title">{title}</h5>
        <p className="card-text">{description}</p>
        {linkTo && (
          <Link to={linkTo} className={linkClassName}>
            {linkLabel}
          </Link>
        )}
        {children}
      </div>
    </div>
  );
}
