import React from 'react';
import { useTranslation } from 'react-i18next';
import Constant from '../Constant';
import { Checkmark } from '../Util';

export default function NavbarSelectLanguage(props) {
  const { t } = useTranslation();
  const { language, setLanguage } = props;
  const [expand, setExpand] = React.useState(false);

  function changeLanguage(e) {
    setLanguage(e);
    setExpand(false);
  }

  return (
    <>
      <button
        className="nav-link dropdown-toggle btn-unstyle"
        id="navbarDropdownMenuLink"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        onClick={e => setExpand(!expand)}
      >
        {'\u{1f310}'} {t(language)}
      </button>
      <div
        className={`dropdown-menu mb-2 ${
          expand ? 'show animated faster fadeInDown' : ''
        }`}
        aria-labelledby="navbarDropdownMenuLink"
      >
        {Constant.LANGUAGES.map((e, i) => {
          return (
            <button
              key={i}
              className="dropdown-item"
              onClick={ev => changeLanguage(e)}
            >
              {e === language && <Checkmark />} {t(e)}
            </button>
          );
        })}
      </div>
    </>
  );
}
