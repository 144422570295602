import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function SearchBox(props) {
  const { t } = useTranslation();

  const { className = '', q, setQ, onSearch } = props;

  const classNames = [className, 'form-inline', 'SearchBox'];

  const el = React.useRef();

  function handleChange(e) {
    e.preventDefault();
    if (setQ) setQ(e.target.value);
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (q.length <= 0) return;
    if (el.current) {
      el.current.click();
      if (onSearch) onSearch();
    }
  }

  return (
    <form className={classNames.join(' ')} onSubmit={handleSubmit}>
      <input
        className="form-control w-100 bg-white rounded-pill pl-4 pr-5 shadow-sm"
        type="search"
        placeholder={t('Search')}
        aria-label={t('Search')}
        value={q}
        onChange={handleChange}
      />
      <button className="btn-unstyle" type="submit">
        &#x2315;
      </button>
      <Link to={`/search?q=${q}`} ref={el} className="d-none" />
    </form>
  );
}
