import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

export default function NotFound(props) {
  const { t } = useTranslation();
  return (
    <div className="container p-3 pt-5 pb-5 rounded text-center">
      <h1 className="p-3 rounded-pill bg-dark text-white shadow mb-4">
        {t('Not Found')}
      </h1>
      <pre className="p-2 rounded bg-light border">
        <code>{useLocation().pathname}</code>
      </pre>
      <div className="p-3 mt-4 mb-4 text-center">
        <img
          src="/img/undraw_page_not_found_indigo.svg"
          alt=""
          className="w-100"
        />
      </div>
    </div>
  );
}
