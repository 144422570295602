import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import SearchBox from './SearchBox';
import Constant from '../Constant';
import NavbarSelectLanguage from './NavbarSelectLanguage';

export default function Navbar(props) {
  const { t } = useTranslation();

  const { q, setQ, language, setLanguage, darkNavbar = false } = props;

  const { pathname } = useLocation();

  const [expand, setExpand] = React.useState(false);

  const classNames = [
    'navbar navbar-expand-md fixed-top shadow-sm',
    'animated faster slideInDown',
  ];

  if (darkNavbar) {
    classNames.push('navbar-dark bg-dark');
  } else {
    classNames.push('navbar-light bg-light');
  }

  return (
    <nav className={classNames.join(' ')}>
      <Link to="/" className="navbar-brand">
        {Constant.APP_LOGO && <span className="mr-2">{Constant.APP_LOGO}</span>}
        {Constant.APP_NAME}
      </Link>

      <button
        className="navbar-toggler border-0"
        type="button"
        data-toggle="collapse"
        data-target="#navbarCollapse"
        aria-controls="navbarCollapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
        onClick={e => setExpand(!expand)}
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div
        className={`collapse navbar-collapse ${
          expand ? 'show animated faster fadeInDown' : ''
        }`}
        id="navbarCollapse"
      >
        <ul className="navbar-nav mr-auto">
          {Constant.PATHS.map(({ icon, label, to, disabled }, i) => {
            const classNames = ['nav-item'];
            if (pathname === to) classNames.push('active');
            if (disabled) classNames.push('disabled');
            return (
              <li key={i} className={classNames.join(' ')}>
                <Link
                  to={to}
                  className="nav-link"
                  onClick={e => setExpand(false)}
                >
                  {icon && <span className="mr-2">{icon}</span>}
                  <span>{t(label)}</span>
                </Link>
              </li>
            );
          })}

          <li className="nav-item dropdown">
            <NavbarSelectLanguage
              language={language}
              setLanguage={setLanguage}
            />
          </li>
        </ul>

        <SearchBox q={q} setQ={setQ} onSearch={e => setExpand(false)} />
      </div>
    </nav>
  );
}
