import React from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, PageContainer, Row, Col } from '../Util';
import { LiveProvider, LiveEditor, LiveError, LivePreview } from 'react-live';
import PlaygroundSampleCodeList from '../Playground/PlaygroundSampleCodeList';
import PLAYGROUND_SAMPLE_CODE_LIST from '../Playground/PLAYGROUND_SAMPLE_CODE_LIST';

//const EDITOR_THEMES = ['prism-react-renderer'];
// duotone dark theme - https://github.com/FormidableLabs/prism-react-renderer/blob/master/src/themes/duotoneDark.js

const MIN_FONT_SIZE = 8;
const BTN_STYLE = {
  width: 25,
  height: 25,
  lineHeight: 0,
  overflow: 'hidden',
};

export default function Playground(props) {
  const { t } = useTranslation();

  const [code, setCode] = React.useState(PLAYGROUND_SAMPLE_CODE_LIST[0]);
  const [fontSize, setFontSize] = React.useState(16);
  const [active, setActive] = React.useState(false);

  React.useEffect(() => {
    setActive(false);
  }, [code]);

  React.useEffect(() => {
    if (!active) setActive(true);
  }, [active]);

  function reduceFontSize(e) {
    e.preventDefault();
    if (fontSize - 1 >= MIN_FONT_SIZE) {
      setFontSize(fontSize - 1);
    }
  }

  function increaseFontSize(e) {
    e.preventDefault();
    setFontSize(fontSize + 1);
  }

  const css = `
  .LiveEditor-container > div {
    font-size: ${fontSize}px;
  }

  .LiveEditor-container > div > textarea {
    outline: none;
  }
  `;

  return (
    <PageContainer title={t('Playground')} fluid>
      <style>{css}</style>

      <div className="text-center mb-2 animated faster fadeInDown delay-500ms">
        <Badge light pill className="px-4">
          {t('Code Sample')}
        </Badge>
      </div>

      <PlaygroundSampleCodeList
        className="mb-3"
        onChange={code => setCode(code)}
      />

      <div style={{ minHeight: '50vh' }}>
        <LiveProvider code={code} noInline>
          <div className="p-3 bg-light">
            <LiveError />
          </div>

          <Row form>
            <Col n={6}>
              <div className="text-center mb-2 animated faster fadeInDown delay-500ms">
                <Badge light pill className="px-4">
                  {t('Editor')}
                </Badge>
                <div className="float-right">
                  <button
                    className="btn btn-danger btn-sm rounded-circle border shadow-sm m-1 animated faster bounceIn delay-1250ms"
                    style={BTN_STYLE}
                    onClick={reduceFontSize}
                    disabled={fontSize <= MIN_FONT_SIZE}
                  >
                    &#x2212;
                  </button>
                  <button
                    className="btn btn-success btn-sm rounded-circle border shadow-sm m-1 animated faster bounceIn delay-1s"
                    style={BTN_STYLE}
                    onClick={increaseFontSize}
                  >
                    +
                  </button>
                </div>
              </div>
              {active && (
                <div className="LiveEditor-container p-1 bg-white rounded shadow-sm animated faster fadeInLeft">
                  <LiveEditor />
                </div>
              )}
            </Col>
            <Col n={6}>
              <div className="text-center mb-2 animated faster fadeInDown delay-500ms">
                <Badge light pill className="px-4">
                  {t('Preview')}
                </Badge>
              </div>
              {active && (
                <div className="animated faster fadeInRight">
                  <LivePreview />
                </div>
              )}
            </Col>
          </Row>
        </LiveProvider>
      </div>
    </PageContainer>
  );
}
