import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageContainer, Row, Col, InputSwitch, Checkmark } from '../Util';
import Constant from '../Constant';

export default function Theme(props) {
  const { t } = useTranslation();

  const { theme, setTheme, darkNavbar, setDarkNavbar } = props;

  function choose(e) {
    if (setTheme) setTheme(e);
  }

  return (
    <PageContainer title={t('Theme')}>
      <div>
        <InputSwitch
          value={darkNavbar}
          onChange={setDarkNavbar}
          label={t('Dark Navbar')}
          className="h5"
        />
      </div>

      <div className="mb-3">
        <h3 className="text-center">{theme}</h3>
        {/*<div className="p-2 bg-white text-muted border rounded shadow-sm">
          {href}
        </div>*/}
      </div>

      <Row form>
        {Constant.THEMES.map((e, i) => {
          const selected = e === theme;
          return (
            <Col key={i} className="mb-2" sm={4} md={3} lg={2}>
              <div className="card shadow-sm">
                {/*<img src="..." className="card-img-top" alt="..." />*/}
                <div className="card-body text-center">
                  <h5 className="card-title nowrap">
                    {selected && <Checkmark />}
                    <span>{e}</span>
                  </h5>
                  <p className="card-text"></p>
                  <button
                    className={`btn ${
                      selected ? 'btn-primary' : 'btn-outline-primary'
                    } shadow-sm`}
                    onClick={ev => choose(e)}
                  >
                    {t(selected ? 'Choosen' : 'Choose')}
                  </button>
                </div>
              </div>
            </Col>
          );
        })}
      </Row>
    </PageContainer>
  );
}
