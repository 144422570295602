import React from 'react';
import { PageContainer, Section, Form, PreCode } from '../Util';

const TEMPLATE = [
  { label: 'Name' },
  { label: 'Age', type: 'number' },
  { label: 'Bio', type: 'md' },
];

const VALUE = {
  Bio: `### h3\n##### h5\n\n**bold**\n\n_italic_\n\n* list\n* list\n\n1. item\n2. item\n`,
};

export default function FormTest() {
  const [value, setValue] = React.useState(VALUE);

  return (
    <PageContainer fluid title="FormTest">
      <Section mb="3">
        <Form
          template={TEMPLATE}
          value={value}
          onSubmit={e => setValue(e)}
          preview
        />
      </Section>

      <Section>
        <h5 className="text-center">Submitted value</h5>
        <PreCode>{value}</PreCode>
      </Section>
    </PageContainer>
  );
}
